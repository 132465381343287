<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí thông tin Prep</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogInformation"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm thông tin</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Type</th>
                    <th scope="col">Nội dung</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in information">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ displayType(item.type).label }}
                        </p>
                      </td>
                      <td>
                        <!--                      <p class="text-dark-75 font-weight-bolder d-block font-size-lg"></p>-->
                        <div
                          style="
                            max-width: max-content;
                            max-height: 300px;
                            overflow: hidden;
                          "
                          v-html="item.content"
                        ></div>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editInformation(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteInformation(item.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!information.length">
                    <tr>
                      <td colspan="5" style="text-align: center">No Data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogInformation"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add Information</span
              >
              <span class="headline" v-else>Edit Information</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogInformation = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Type:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-select
                        :items="types"
                        item-text="label"
                        item-value="value"
                        v-model="type_input"
                        filled
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nội dung:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><Editor v-model="content_input"></Editor
                    ></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditInformation(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditInformation(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogInformation = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import Editor from "@/components/ckeditor5/Editor";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "ListInformation",
  components: {
    Editor,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      information: [],
      types: [
        { label: "Điều kiện giao dịch và hình thức thanh toán", value: 1 },
        {
          label: "Biểu phí các chương trình và Quy trình cung cấp dịch vụ",
          value: 2,
        },
        {
          label: "Chính sách bảo vệ thông tin cá nhân của người tiêu dùng",
          value: 3,
        },
      ],
      type_input: null,
      content_input: null,
      dialogInformation: false,
      information_id: null,
      type_form: null,
    };
  },
  created() {
    this.getPrepInformation();
  },
  methods: {
    async getPrepInformation() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/information");
        if (res.status === 200) {
          vm.information = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogInformation() {
      this.type_form = "add";
      this.content_input = "";
      this.type_input = null;
      this.dialogInformation = true;
    },
    btnAddOrEditInformation(type) {
      let vm = this;
      let data = {
        content: this.content_input,
        type: this.type_input,
      };
      this.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/information", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.getPrepInformation();
              vm.is_call_api = false;
              vm.dialogInformation = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put("prep-app/information/" + vm.information_id, data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.getPrepInformation();
              vm.is_call_api = false;
              vm.dialogInformation = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    editInformation(index) {
      let data = this.information[index];
      this.information_id = data.id;
      this.content_input = data.content == null ? "" : data.content;
      this.type_input = data.type;
      this.type_form = "update";
      this.dialogInformation = true;
    },
    deleteInformation(information_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/information/" + information_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getPrepInformation();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    displayType(type) {
      let data = this.types.filter((e) => {
        return e.value == type;
      });
      return data[0];
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
